import { Button, Dialog, DialogActions, DialogContent, ImageList, ImageListItem, TextField, Tooltip, Zoom } from "@mui/material"
import dayjs from "dayjs"
import { forwardRef, useRef, useState } from "react"
import { generateImageAI } from "services/chat.services"
import { isValidValue } from "utils"
import "./index.css"

const Transition = forwardRef(function Transition(props, ref) {
	return <Zoom ref={ref} {...props} />
})

// '256x256', '512x512', '1024x1024', '1024x1792', '1792x1024'
export default function ChatPage() {
	const [dialog, setDialog] = useState([])
	const [isLoadingRequest, setIsLoadingRequest] = useState(false)
	const [imageDetail, setImageDetail] = useState(false)
	const [numberOfImg, setNumberOfImg] = useState(4)
	const [widthGen, setWidthGen] = useState(512)
	const [heightGen, setHeightGen] = useState(512)
	const textChatRef = useRef()

	const handleSubmitTextToGenerate = async (e) => {
		e.preventDefault()
		const text = textChatRef.current.value
		if (isValidValue(text)) {
			textChatRef.current.value = ""
			handleRequestNewImage(text)
		}
	}

	const handleRequestNewImage = async (text) => {
		setDialog((prevState) => {
			return [
				...prevState,
				{
					type: "MAN",
					created: dayjs().unix(),
					data: text,
				},
			]
		})
		setIsLoadingRequest(true)
		try {
			const result = await generateImageAI({
				number: numberOfImg,
				size: `${widthGen}x${heightGen}`,
				prompt: text,
			})
			setIsLoadingRequest(false)
			if (result.data.created) {
				const newData = { ...result.data, type: "AI" }

				setDialog((prevState) => {
					return [...prevState, newData]
				})
			} else {
				setDialog((prevState) => {
					return [
						...prevState,
						{
							type: "AI",
							created: dayjs().unix(),
							text: result.data || "Không get được dữ liệu",
						},
					]
				})
			}
		} catch (error) {
			setIsLoadingRequest(false)
			setDialog((prevState) => {
				return [
					...prevState,
					{
						type: "AI",
						created: dayjs().unix(),
						text: "Không get được dữ liệu",
					},
				]
			})
		}
	}

	return (
		<>
			<div>
				<div className="container-fluid">
					<div className="row">
						<div className="col-12">
							<div style={{ minHeight: "90vh" }}>
								{dialog.map((item, key) => (
									<Tooltip key={key} title={dayjs(item.created).format("HH:mm DD/MM/YYYY")} arrow>
										<div key={key} className={item.type == "AI" ? "receive--chat" : "user--chat"}>
											{item.type == "AI" ? (
												item.data ? (
													<ImageList sx={{ width: 1000, height: 250, marginBottom: 0 }} variant="quilted" cols={4} gap={8}>
														{item.data.map((image, keyImage) => (
															<ImageListItem key={keyImage} cols={item.cols || 1} rows={item.rows || 1}>
																<img onClick={() => setImageDetail(image.url)} src={image.url} loading="lazy" style={{ cursor: "pointer", width: "100%", height: "auto" }} />
															</ImageListItem>
														))}
													</ImageList>
												) : (
													<span>{item.text}</span>
												)
											) : (
												<span>{item.data}</span>
											)}
										</div>
									</Tooltip>
								))}
								{isLoadingRequest && (
									<div className={"receive--chat"}>
										<div style={{ width: 50 }}>
											<span className="loader"></span>
										</div>
									</div>
								)}
							</div>
						</div>
					</div>
					<div className="col-12 position-sticky bg-light pb-4" style={{ bottom: 0 }}>
						<form onSubmit={handleSubmitTextToGenerate}>
							<TextField disabled={isLoadingRequest} inputRef={textChatRef} placeholder="Type something to generate" variant="outlined" fullWidth />
						</form>
					</div>
				</div>

				<Dialog open={Boolean(imageDetail)} TransitionComponent={Transition} keepMounted onClose={() => setImageDetail(null)}>
					<DialogContent>
						<img src={imageDetail} alt="" />
					</DialogContent>
					<DialogActions>
						<Button onClick={() => setImageDetail(null)}>Close</Button>
					</DialogActions>
				</Dialog>
			</div>
		</>
	)
}
