import "./App.css"
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import "./index.css"
import ChatPage from "./pages/Chat"
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";

const router = createBrowserRouter([
	{
		path: "/",
		element: <ChatPage />,
	},
])
function App() {
	return <RouterProvider router={router} />
}

export default App
